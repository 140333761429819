import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const StarDealButton = ({
  className,
  parentClick,
  tag: Tag,
}) => {
  const dispatch = useDispatch();
  const starDeal = useSelector(state => state.starDeal);
  const slug = starDeal?.slug;
  const type = starDeal?.type;
  const starDealLink = `/${type}/${slug}`;

  const handleClick = () => {
    if (parentClick && typeof parentClick === 'function') {
      parentClick();
    }

    dispatch({
      type: 'frontend/click/CLICK_INTERACTION',
      payload: { section: 'SecondaryNavLinks', value: starDealLink },
    });
  };

  if (starDeal) {
    if (Tag !== 'a') {
      return (
        <Tag className={`nav-star-deal ${className}`} onClick={handleClick}>
          <Link to={`/${type}/${slug}`}>
            <FormattedMessage id="nav.stardeal" defaultMessage="Star Deal" />
          </Link>
        </Tag>
      );
    }

    return (
      <Link
        to={`/${type}/${slug}`}
        className={className}
        onClick={handleClick}
      >
        <div className="nav-star-deal star-deal-button">
          <FormattedMessage id="nav.stardeal" defaultMessage="Star Deal" />
        </div>
      </Link>
    );
  }

  return false;
};

StarDealButton.propTypes = {
  className: PropTypes.string,
  parentClick: PropTypes.func,
  tag: PropTypes.string,
};

StarDealButton.defaultProps = {
  className: '',
  parentClick: null,
  tag: 'a',
};

export default StarDealButton;
