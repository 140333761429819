import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import DropdownMenu from '../Utility/DropdownMenu';
import Dropdown from '../Utility/Dropdown';
import DropdownToggle from '../Utility/DropdownToggle';
import { history } from '../../redux/store';
import Logout from '../../containers/Auth/Logout';
import ThemeToggle from '../../containers/Utility/ThemeToggle';
import { isBulker } from '../../Helpers/User/Traits';
import { toggleLoginModal } from '../../redux/ducks/modal';

const LoginBtn = ({ className, parentClick }) => {
  const [toggled, setToggled] = useState(false);
  const isLoggedIn = useSelector(state => state?.auth?.authenticated);
  const email = useSelector(state => state?.auth?.email);
  const dispatch = useDispatch();

  useEffect(() => {
    const unlisten = history.listen(() => setToggled(false));

    const addEventListeners = () => {
      const element = document.getElementById('account-link');
      if (element) {
        element.addEventListener('mouseover', () => setToggled(true));
        element.addEventListener('mouseleave', () => setToggled(false));
      }
    };

    if (isLoggedIn) {
      addEventListeners();
    }

    return () => {
      const element = document.getElementById('account-link');
      if (element) {
        element.removeEventListener('mouseover', () => setToggled(true));
        element.removeEventListener('mouseleave', () => setToggled(false));
      }
      unlisten();
    };
  }, [isLoggedIn]);

  const handleClick = () => {
    if (parentClick) parentClick();
  };

  const doLogin = () => {
    handleClick();
    dispatch(toggleLoginModal());
  };

  const toggle = () => {
    setToggled(prevState => !prevState);
  };

  const showProductLibrary = !isBulker();

  return (
    <div className="d-flex w-100">
      {isLoggedIn &&
      <Dropdown
        isOpen={toggled}
        toggle={toggle}
        className="justify-content-end navbar-nav nav-item w-100"
        id="account-link"
      >
        <DropdownToggle
          nav
          caret
          className="nav-account-dropdown"
        >
          <FormattedMessage id="nav.login.youraccount" defaultMessage="My Account" />
        </DropdownToggle>
        <DropdownMenu>
          <React.Fragment>
            <DropdownItem header className="text-muted">
              <div className="mb-0">
                <FormattedMessage
                  id="nav.login.loggedinas"
                  defaultMessage="Logged in as {emailAddress}"
                  values={{
                    emailAddress: <div className="pt-2 pb-2">{ email }</div>,
                  }}
                />
              </div>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="acccount-dropdown-item" tag={Link} to="/account" onClick={handleClick}>
              <FontAwesomeIcon icon="user" size="lg" />
              <FormattedMessage id="nav.login.yourprofile" defaultMessage="Account Overview" />
            </DropdownItem>
            <DropdownItem className="acccount-dropdown-item" tag={Link} to="/orders" onClick={handleClick}>
              <FontAwesomeIcon icon="key" size="lg" />
              <FormattedMessage id="nav.login.orders" defaultMessage="Order History & Keys" />
            </DropdownItem>

            {showProductLibrary &&
            <DropdownItem tag={Link} className="acccount-dropdown-item" to="/product-library" onClick={handleClick}>
              <FontAwesomeIcon icon="book-open" size="lg" />
              <FormattedMessage id="nav.login.product" defaultMessage="Product Library" />
            </DropdownItem>
                }

            <DropdownItem className="acccount-dropdown-item" tag={Link} to="/account/wishlist" onClick={handleClick}>
              <FontAwesomeIcon icon="heart" size="lg" />
              <FormattedMessage id="nav.login.wishlist" defaultMessage="My Wishlist" />
            </DropdownItem>

            {!isBulker() &&
            <DropdownItem className="acccount-dropdown-item" tag={Link} to="/reviews" onClick={handleClick}>
              <FontAwesomeIcon icon="star" size="lg" />
              <FormattedMessage id="nav.login.myreviews" defaultMessage="My Reviews" />
            </DropdownItem>
                }

            <ThemeToggle place="account-dropdown" />
            <DropdownItem divider />
            <Logout tag={DropdownItem} className="acccount-dropdown-item">
              <FontAwesomeIcon icon="sign-out-alt" size="lg" />
              <FormattedMessage id="nav.login.logout" defaultMessage="Sign Out" />
            </Logout>
          </React.Fragment>
        </DropdownMenu>
      </Dropdown>
        }
      {!isLoggedIn &&
      <Button color="link" className={`${className} w-100`} onClick={doLogin}>
        <FormattedMessage id="nav.login.login" defaultMessage="Sign in" />
      </Button>
        }
    </div>
  );
};

LoginBtn.propTypes = {
  className: PropTypes.string,
  parentClick: PropTypes.func,
};

LoginBtn.defaultProps = {
  className: '',
  parentClick: () => {},
};

export default LoginBtn;
