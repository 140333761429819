import React from 'react';

import ViewportContext from '../../components/App/viewport-context';

// https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

const smScreenWidth = 576;
const mdScreenWidth = 768;
const lgScreenWidth = 992;
const xlScreenWidth = 1200;
const xxlScreenWidth = 1520;

const isTouchDevice = () => (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

/**
 * useViewport retrives the width and height of the view port
 * @return {Object} width and height of the viewport
 */
const useViewport = () => {
  const { width, height } = React.useContext(ViewportContext);

  return {
    isXs: width < smScreenWidth,
    isAboveXs: width >= smScreenWidth,

    isBelowSm: width < smScreenWidth,
    isSm: width >= smScreenWidth && width < mdScreenWidth,
    isAboveSm: width >= mdScreenWidth,

    isBelowMd: width < mdScreenWidth,
    isMd: width >= mdScreenWidth && width < lgScreenWidth,
    isAboveMd: width >= lgScreenWidth,

    isBelowLg: width < lgScreenWidth,
    isLg: width >= lgScreenWidth && width < xlScreenWidth,
    isAboveLg: width >= xlScreenWidth,

    isBelowXl: width < xlScreenWidth,
    isXl: width >= xlScreenWidth && width < xxlScreenWidth,
    isAboveXl: width >= xxlScreenWidth,

    isBelowXxl: width < xxlScreenWidth,
    isXxl: width >= xxlScreenWidth,

    isTouchDevice: isTouchDevice(),

    width,
    height,
  };
};

export default useViewport;
