/**
 * Replacement for component from ReactStrap, removing popper dependency
 */

import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownContext } from 'reactstrap/lib/DropdownContext';
import { mapToCssModules, omit, keyCodes } from 'reactstrap/lib/utils';

const preventDefaultKeys = [
  keyCodes.space,
  keyCodes.enter,
  keyCodes.up,
  keyCodes.down,
  keyCodes.end,
  keyCodes.home,
];

const Dropdown = (props) => {
  const {
    className,
    cssModule,
    direction,
    isOpen,
    group,
    size,
    nav,
    setActiveFromChild,
    active,
    addonType,
    tag,
    menuRole,
    ...attrs
  } = omit(props, ['toggle', 'disabled', 'inNavbar', 'a11y']);

  const containerRef = useRef(null);
  const menuRef = useRef(null);
  const context = useContext(DropdownContext);

  const handleMenuRef = () => {
    menuRef.current = menuRef;
  };

  const getContextValue = () => ({
    toggle: props.toggle,
    isOpen: props.isOpen,
    direction: (props.direction === 'down' && props.dropup) ? 'up' : props.direction,
    inNavbar: props.inNavbar,
    disabled: props.disabled,
    onMenuRef: handleMenuRef,
    menuRole: props.menuRole,
  });

  const getContainer = () => containerRef.current;
  const getMenu = () => menuRef.current;

  const getMenuCtrl = () => {
    if (containerRef.current) {
      return containerRef.current.querySelector('[aria-expanded]');
    }
    return null;
  };

  const getMenuItems = useCallback(() => {
    const getItemType = () => (context.menuRole === 'listbox' ? 'option' : 'menuitem');
    const menuContainer = getMenu() || getContainer();
    return [].slice.call(menuContainer.querySelectorAll(`[role="${getItemType()}"]`));
  }, [context.menuRole]);

  const handleDocumentClick = useCallback((e) => {
    if (e && (e.which === 3 || (e.type === 'keyup' && e.which !== keyCodes.tab))) return;
    const container = getContainer();
    const menu = getMenu();
    const clickIsInContainer = container.contains(e.target) && container !== e.target;
    const clickIsInMenu = menu && menu.contains(e.target) && menu !== e.target;
    if ((clickIsInContainer || clickIsInMenu) && (e.type !== 'keyup' || e.which === keyCodes.tab)) {
      return;
    }

    props.toggle(e);
  }, [props]);

  const addEvents = useCallback(() => {
    ['click', 'touchstart', 'keyup'].forEach((event) => {
      document.addEventListener(event, handleDocumentClick, true);
    });
  }, [handleDocumentClick]);

  const removeEvents = useCallback(() => {
    ['click', 'touchstart', 'keyup'].forEach((event) => {
      document.removeEventListener(event, handleDocumentClick, true);
    });
  }, [handleDocumentClick]);

  const handleKeyDown = useCallback((e) => {
    const isTargetMenuItem = e.target.getAttribute('role') === 'menuitem' || e.target.getAttribute('role') === 'option';
    const isTargetMenuCtrl = getMenuCtrl() === e.target;
    const isTab = keyCodes.tab === e.which;

    if (
      /input|textarea/i.test(e.target.tagName)
      || (isTab && !props.a11y)
      || (isTab && !(isTargetMenuItem || isTargetMenuCtrl))
    ) {
      return;
    }

    if (preventDefaultKeys.indexOf(e.which) !== -1 || ((e.which >= 48) && (e.which <= 90))) {
      e.preventDefault();
    }

    if (props.disabled) return;

    if (isTargetMenuCtrl) {
      if ([keyCodes.space, keyCodes.enter, keyCodes.up, keyCodes.down].indexOf(e.which) > -1) {
        if (!props.isOpen) {
          props.toggle(e);
        }
        setTimeout(() => getMenuItems()[0].focus());
      } else if (props.isOpen && isTab) {
        e.preventDefault();
        getMenuItems()[0].focus();
      } else if (props.isOpen && e.which === keyCodes.esc) {
        props.toggle(e);
      }
    }

    if (props.isOpen && isTargetMenuItem) {
      if ([keyCodes.tab, keyCodes.esc].indexOf(e.which) > -1) {
        props.toggle(e);
        getMenuCtrl().focus();
      } else if ([keyCodes.space, keyCodes.enter].indexOf(e.which) > -1) {
        e.target.click();
        getMenuCtrl().focus();
      } else if (
        [keyCodes.down, keyCodes.up].indexOf(e.which) > -1
        || ([keyCodes.n, keyCodes.p].indexOf(e.which) > -1 && e.ctrlKey)
      ) {
        const $menuitems = getMenuItems();
        let index = $menuitems.indexOf(e.target);
        if (keyCodes.up === e.which || (keyCodes.p === e.which && e.ctrlKey)) {
          index = index !== 0 ? index - 1 : $menuitems.length - 1;
        } else if (keyCodes.down === e.which || (keyCodes.n === e.which && e.ctrlKey)) {
          index = index === $menuitems.length - 1 ? 0 : index + 1;
        }
        $menuitems[index].focus();
      } else if (keyCodes.end === e.which) {
        const $menuitems = getMenuItems();
        $menuitems[$menuitems.length - 1].focus();
      } else if (keyCodes.home === e.which) {
        const $menuitems = getMenuItems();
        $menuitems[0].focus();
      }
    }
  }, [props, getMenuItems]);

  useEffect(() => {
    if (props.isOpen) {
      addEvents();
    } else {
      removeEvents();
    }

    return () => {
      removeEvents();
    };
  }, [props.isOpen, addEvents, removeEvents]);

  const Tag = tag || (nav ? 'li' : 'div');

  let subItemIsActive = false;
  if (setActiveFromChild) {
    React.Children.map(props.children[1].props.children,
      (dropdownItem) => {
        if (dropdownItem && dropdownItem.props.active) subItemIsActive = true;
      },
    );
  }

  const classes = mapToCssModules(classNames(
    className,
    direction !== 'down' && `drop${direction}`,
    nav && active ? 'active' : false,
    setActiveFromChild && subItemIsActive ? 'active' : false,
    {
      [`input-group-${addonType}`]: addonType,
      'btn-group': group,
      [`btn-group-${size}`]: !!size,
      dropdown: !group && !addonType,
      show: isOpen,
      'nav-item': nav,
    },
  ), cssModule);

  return (
    <DropdownContext.Provider value={getContextValue()}>
      <Tag
        {...attrs}
        {...{ [typeof Tag === 'string' ? 'ref' : 'innerRef']: containerRef }}
        className={classes}
        onKeyDown={handleKeyDown}
      />
    </DropdownContext.Provider>
  );
};

const propTypes = {
  a11y: PropTypes.bool,
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.node,
  inNavbar: PropTypes.bool,
  menuRole: PropTypes.oneOf(['listbox', 'menu']),
};

const defaultProps = {
  a11y: true,
  isOpen: false,
  direction: 'down',
  nav: false,
  active: false,
  addonType: false,
  inNavbar: false,
  setActiveFromChild: false,
};


Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
