import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import round from 'lodash/round';

import { currencyCode } from '../../../Helpers/Geo/data';

const SpecialFormattedNumber = ({
  style,
  className,
  dontConvert,
  currency,
  hideDecimals,
  value: _value,
}) => {
  let value = _value;
  const nativeRates = useSelector(state => state.nativeRates);

  if (style !== 'currency') {
    return (
      <FormattedNumber
        className={className}
        value={value}
        style={style}
      />
    );
  }

  let decimals = Number(get(window, 'countryData.Currency-Minor-Unit', 2));

  // On order and receipt page we take currency from order, not from frontend setting
  let currencyToDisplay = currency || currencyCode;

  if (style === 'currency' && nativeRates.to && nativeRates.from === currencyToDisplay && !dontConvert) {
    currencyToDisplay = nativeRates.to;
    value *= nativeRates.rate;

    decimals = Number(get(window, 'countryData.Native-Currency-Minor-Unit', 2));
    value = round(value, decimals);
  }

  if (currencyToDisplay === 'RUB' || currencyToDisplay === 'JPY') {
    value = Number(value).toFixed(2);
    const totalArray = value.split('.');
    if (totalArray[1] === '00') {
      decimals = 0;
    }
  }

  if (hideDecimals) decimals = 0;

  return (
    <FormattedNumber
      minimumFractionDigits={decimals}
      className={className}
      value={value}
      style={style}
      currency={currencyToDisplay}
    />
  );
};

SpecialFormattedNumber.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
  dontConvert: PropTypes.bool,
  currency: PropTypes.string,
  hideDecimals: PropTypes.bool,
  value: PropTypes.number,
};

SpecialFormattedNumber.defaultProps = {
  style: 'currency',
  className: '',
  dontConvert: false,
  currency: null,
  hideDecimals: false,
  value: 0,
};

export default SpecialFormattedNumber;
