/**
 * Split tests to use in the app
 * Can be passed as a string (name) or an object with configuration
 * See defaults in split-test duck for default variants and other available options
 * @type {String|Array.Object}
 */

module.exports = [
  'MegaMenuBundleHighlights', // Test 380
  'ProductCarouselTrustPilot', // Test 381
  'smartCartRecommendations', // Test 382
  'smartProductRecommendations', // Test 383
  'CartUndoWithDelay', // Test 386
  'SeptemberSaversBanner', // Test 384
  'MobileEditionSelector', // Test 385
  'PNMCardUserReviews', // Test 353
  'BundleCardTimerV2', // Test 390
];
