import memoize from 'lodash/memoize';

/**
 * Take the bootstrap grid sizes for a hit card and determine default
 * sizes override string for the product cover FluidImg
 * @param  {Number} lgSize
 * @param  {Number} mdSize
 * @param  {Number} smSize
 * @param  {Number} xsSize
 * @return {String}
 */
function imageSizes(lgSize, mdSize, smSize, xsSize) {
  const breakpoints = [
    {
      name: 'xs',
      minWidth: 0,
      padding: 8,
    }, {
      name: 'sm',
      minWidth: 576,
      padding: 8,
      containerMax: 540,
    }, {
      name: 'md',
      minWidth: 768,
      padding: 8,
      containerMax: 720,
    }, {
      name: 'lg',
      minWidth: 992,
      padding: 12,
      containerMax: 960,
    }, {
      name: 'xl',
      minWidth: 1200,
      padding: 12,
      containerMax: 1140,
    }, {
      name: 'xxl',
      minWidth: 1520,
      padding: 16,
      containerMax: 1340,
    },
  ];

  const sizes = breakpoints.reverse().map((breakpoint) => {
    if (!breakpoint.containerMax) {
      return `${(xsSize / 12) * 100}vw`;
    }

    let size = lgSize;

    if (breakpoint.name === 'xs') {
      size = xsSize;
    } else if (breakpoint.name === 'sm') {
      size = smSize;
    } else if (breakpoint.name === 'md') {
      size = mdSize;
    }

    return `(min-width: ${breakpoint.minWidth}px) ${Math.ceil((size / 12) * (breakpoint.containerMax + (breakpoint.padding * 2) - 16) - (breakpoint.padding * 2))}px`;
  }).join(', ');

  return sizes;
}

export default memoize(imageSizes);
