import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';

import {
  postEmail,
  EMAIL_SUBSCRIBE,
  EMAIL_ERROR,
} from '../../redux/ducks/email-subscribe';
import { toggleEmailSubscribeModal as toggleEmailSubscribeModalAction } from '../../redux/ducks/modal';
import { toggleFeedbackPopup as toggleFeedbackPopupAction } from '../../redux/ducks/feedback-popups';
import store from '../../redux/store';

import './EmailSubscribeInput.scss';

const EmailSubscribeInput = ({
  auth,
  emailSubscribe,
  mobileEnabled,
  toggleEmailSubscribeModal,
  toggleFeedbackPopup,
  appearsOn,
}) => {
  const [email, setEmail] = useState('');

  const intl = useIntl();

  const context = appearsOn === 'home' ? 'nlmodule_tophomepage' : 'nlmodule_topfantasyverse';

  const handleChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value, false);
  };

  const optinEmail = (event, emailPassed, optin) => {
    event.preventDefault();
    store.dispatch(postEmail(emailPassed, context, optin, true));
  };

  const handleSubmit = (event) => {
    optinEmail(event, email, true);
  };

  // Used to trigger the feedback popups
  useEffect(() => {
    const messages = defineMessages({
      successTitle: {
        id: 'subscribe.inputmodal.success.title',
        defaultMessage: 'Success !',
      },
      successMessage: {
        id: 'subscribe.inputmodal.success.message',
        defaultMessage: 'You are now subscribed to the Fanatical newsletter.',
      },
      errorTitle: {
        id: 'subscribe.inputmodal.error.title',
        defaultMessage: 'Something went wrong',
      },
      errorMessage: {
        id: 'subscribe.inputmodal.error.message',
        defaultMessage: 'Whoops something went wrong please try again.',
      },
    });

    if (emailSubscribe.currentState === EMAIL_SUBSCRIBE && emailSubscribe.context === context) {
      toggleFeedbackPopup({ type: 'success', title: intl.formatMessage(messages.successTitle), message: intl.formatMessage(messages.successMessage) });
      localStorage.setItem('navSubscribedDate', Date.now());
    }

    if (emailSubscribe.currentState === EMAIL_ERROR && emailSubscribe.context === context) {
      toggleFeedbackPopup({ type: 'error', title: intl.formatMessage(messages.errorTitle), message: intl.formatMessage(messages.errorMessage) });
    }
  }, [emailSubscribe.currentState, emailSubscribe.context, intl, toggleFeedbackPopup, context]);

  if (mobileEnabled) {
    return (
      <div id={`emailsignup-${context}`} className="email-subscribe-input">
        <button
          className="btn-block email-single-btn pb-0 pt-0 pl-3 pr-3"
          onClick={() => toggleEmailSubscribeModal()}
        >
          <FormattedMessage id="subscribe.input.mobile-subscribe" defaultMessage="Subscribe" />
        </button>
      </div>
    );
  }

  return (
    <div id={`emailsignup-${context}`} className="email-subscribe-input">
      {auth.authenticated &&
        <button
          className="btn-block email-single-btn pb-0 pt-0 pl-3 pr-3"
          onClick={event => optinEmail(event, auth.email, true)}
        >
          <FormattedMessage id="subscribe.input.button-subscribe" defaultMessage="Subscribe" />
        </button>
      }
      {!auth.authenticated &&
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <div className="input-group" style={{ width: '280px' }}>
              <input
                required
                placeholder={intl.formatMessage({ id: 'email-subscribe-nav.placeholder', defaultMessage: 'Enter your email' })}
                className="form-control"
                type="email"
                name="email"
                title="Enter your email address"
                enterKeyHint="send"
                aria-label="email"
                value={auth.email}
                onChange={handleChange}
              />
              <span className="input-group-append">
                <button className="btn btn-primary pb-0 pt-0 pl-3 pr-3" type="submit">
                  <FormattedMessage id="subscribe.input.input-subscribe" defaultMessage="Subscribe" />
                </button>
              </span>
            </div>
          </form>
        </React.Fragment>
      }
    </div>
  );
};

EmailSubscribeInput.propTypes = {
  auth: PropTypes.shape({}),
  emailSubscribe: PropTypes.shape({}),
  mobileEnabled: PropTypes.bool,
};

EmailSubscribeInput.defaultProps = {
  auth: {},
  emailSubscribe: {},
  mobileEnabled: false,
};

const mapStateToProps = ({
  auth,
  emailSubscribe,
}) => ({
  auth,
  emailSubscribe,
});

const mapDispatchToProps = {
  toggleEmailSubscribeModal: toggleEmailSubscribeModalAction,
  toggleFeedbackPopup: toggleFeedbackPopupAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscribeInput);
